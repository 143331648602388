<template>
    <div class="modal fade" id="kt_modal_meeting_recording" ref="meetingRecordingModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ $t('pages.module.zoom.meeting.recording.title') }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <custom-table
                            :runModeServer="false"
                            :title="$t('pages.module.zoom.meeting.recording.title')"
                            rowKey="id"
                            :items="table.data"
                            :loading="table.loading"
                            :columns="fields"
                            :actions="actions"
                            :pagination="table.pagination"
                            @action="handleClickAction"
                            class="border border-gray-300">
                            <template v-slot:size="{ row: record }">
                                {{ bytesToSize(record.file_size) }}
                            </template>
                            <template v-slot:download="{ row: record }">
                                <div class="d-flex justify-content-end">
                                    <el-tooltip :content="$t('pages.module.zoom.meeting.recording.cols.download')" placement="top">
                                        <a v-on:click="downloadEvent(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                                            </span>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </template>
                        </custom-table>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" data-bs-dismiss="modal">
                        <span class="indicator-label">{{ $t("btn.close") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.module.zoom.meeting.recording.cols.fileType"),
                    key: "recording_type",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.recording.cols.fileType"),
                    key: "file_type",
                },
                {
                    name: this.$t("pages.module.zoom.meeting.recording.cols.size"),
                    scopedSlots: {customRender: "size"},
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.recording.cols.download"),
                    key: "download",
                    class: "",
                    scopedSlots: {customRender: "download"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            table: {
                loading: false,
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                }
            },
            meetingID: undefined,
            meetingRecording: {},
            responseMessages: {
                meeting_recording_fetch_error: "meetingRecordingFetchError",
            },
        }
    },
    mounted() {
        this.eventBus.$on('showMeetingRecording', (meetingID) => {
            this.$emit('recordingLoading', true);

            this.meetingID = meetingID;

            this.table.data = [];
            this.table.pagination.current = 1;

            this.loadMeetingRecording(true);
        });
    },
    beforeUnmount() {
        this.eventBus.$off('showMeetingRecording', undefined, true);
    },
    methods: {
        loadMeetingRecording(showModal = false) {
            this.table.loading = true;

            this.axios.get(this.endpoints['module_zoom_meeting_recording'] + '/' + this.meetingID).then((response) => {
                let data = response.data.data;
                this.meetingRecording = data;
                this.table.data = data.recording_files ?? [];
                this.table.pagination.total = this.table.data.length;

                if(showModal) {
                    this.showModal(this.$refs.meetingRecordingModal);
                }
            }).catch(error => {
                if(this.responseMessages[error.response.data.message]) {
                    error.response.data.message = this.$t('pages.module.zoom.meeting.recording.responseMessages.' + this.responseMessages[error.response.data.message]);
                }

                this.onResponseFailure(error.response.data);
            }).finally(()=> {
                this.$emit('recordingLoading', false);
                this.table.loading = false;
            })
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadMeetingRecording();
                    break;
                default:
                    break;
            }
        },
        downloadEvent(record){
            window.open(record.download_button_url, '_blank');
        }
    }
}
</script>

<style scoped>

</style>