<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("menu.modules") }}
        </router-link>
        <router-link to="/module/zoom/host" class="btn btn-primary align-self-center ms-3">
            {{ $t("pages.module.zoom.host.title") }}
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
            </span>
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.zoom.meeting.title')"
        :subTitle="$t('pages.module.zoom.meeting.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:start_date="{ row: record }">
            {{ record.start_date ? $moment(record.start_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:host="{ row: record }">
            {{ resolveDatum(hosts, record.host_id).host }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/module/zoom/meeting/detail/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                     </span>
                </router-link>
                <el-tooltip :content="$t('pages.module.zoom.meeting.recording.title')" placement="top">
                    <a v-on:click="showMeetingRecording(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" :data-kt-indicator="meetingRecordingLoading ? 'on' : null" :class="meetingRecordingLoading && 'disabled'">
                        <span class="svg-icon svg-icon-3" v-if="!meetingRecordingLoading">
                            <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                        </span>
                        <span v-if="meetingRecordingLoading" class="indicator-progress">
                            <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_meeting" ref="meetingModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="meetingForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.host') }}</label>
                                <el-form-item prop="host_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.host_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(host, hostIndex) in hosts" :key="hostIndex" :value="host.id" :label="host.host"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.meetingType') }}</label>
                                <el-form-item prop="meeting_type_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.meeting_type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(meetingType, meetingTypeIndex) in meetingTypes" :key="meetingTypeIndex" :value="meetingType.id" :label="meetingType.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">
                                    {{ $t('pages.module.zoom.meeting.cols.approvalType') }}

                                    <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.module.zoom.meeting.informationBoxes.approvalType')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="options.approval_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.options.approval_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option :value="1" :label="$t('pages.module.zoom.meeting.approvalTypes.manualApproval')"></el-option>
                                        <el-option :value="2" :label="$t('pages.module.zoom.meeting.approvalTypes.noApprovalRequired')"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-4 code-form-row">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.password') }}</label>
                                <el-form-item prop="password" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.password" type="text" v-mask="{regex: '^[a-zA-Z0-9 ]+$'}" maxlength="10"/>
                                    <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer" v-on:click="generateCode">
                                        <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-2 me-1">
                                                <inline-svg src="/media/icons/duotune/arrows/arr029.svg"/>
                                            </span>
                                            {{ $t('pages.module.zoom.meeting.generateCode') }}
                                        </div>
                                    </a>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.topic') }}</label>
                                <el-form-item prop="topic" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.topic" type="text"  maxlength="200"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.startDate') }}</label>
                                <el-form-item prop="start_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.start_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledStartDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.duration') }}</label>
                                <el-form-item prop="duration" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.duration" :min="1"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.agenda') }}</label>
                                <el-form-item prop="agenda">
                                    <el-input v-model="form.data.agenda" type="textarea" rows="5" maxlength="2000"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <Recording @recordingLoading="meetingRecordingLoading = $event"></Recording>
</template>

<script>
import CustomTable from "@/components/custom-table";
import Recording from "@/components/module/zoom/meeting/recording";

export default {
    name: "product",
    components: {
        CustomTable,
        Recording
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.meetingID"),
                    key: "meeting_id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.topic"),
                    key: "topic",
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.meetingType"),
                    key: "meeting_type.name",
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.startDate"),
                    scopedSlots: {customRender: "start_date"}
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.duration"),
                    key: "duration",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.registrantCount"),
                    key: "registrants_count",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.cols.host"),
                    scopedSlots: {customRender: "host"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    options: {
                        approval_type: 1
                    }
                }
            },
            meetingRecordingLoading: false,
            responseMessages: {
                meeting_cannot_be_past: "meetingCannotBePast",
            },
        }
    },
    computed: {
        table() {
            return this.$store.state.module.zoom.meeting.table;
        },
        hosts() {
            return this.$store.state.module.zoom.host.table.data;
        },
        meetingTypes() {
            return this.$store.state.module.zoom.meeting.type.table.data;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.zoom.meeting.title"), [this.$t("pages.module.zoom.title")]);
        this.$store.dispatch('module/zoom/meeting/get', {
            page: {},
            filterData: {
                sort: 'created_at:desc',
            }
        });
        this.$store.dispatch('module/zoom/host/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('module/zoom/meeting/type/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        generateCode(){
            this.axios.get(this.endpoints['module_zoom_meeting_generate_code'], {
                params: {
                    limit: 10
                }
            }).then(response => {
                if (response.data.status) {
                    let data = response.data.data;
                    this.form.data.password = data.code;
                }
            });
        },
        newMeeting(){
            this.form.updateStatus = false;
            this.form.data = {
                duration: 60,
                options: {
                    approval_type: 1
                }
            };
            this.form.title = this.$t("pages.module.zoom.meeting.newMeeting");

            this.generateCode();
            this.showModal(this.$refs.meetingModal);
        },
        onSubmit(){
            this.$refs.meetingForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoints['module_zoom_meeting'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.meetingModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        if(this.responseMessages[error.response.data.message]) {
                            error.response.data.message = this.$t('pages.module.zoom.meeting.responseMessages.' + this.responseMessages[error.response.data.message]);
                        }

                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });

                    /*if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_zoom_meeting'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.meetingModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['module_zoom_meeting'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.meetingModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } */
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/zoom/meeting/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newMeeting();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/zoom/meeting/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/zoom/meeting/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc',
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        disabledStartDate(startDate) {
            startDate = this.$moment(startDate);
            return this.$moment().add(-1, 'days').valueOf() > startDate.valueOf();
        },
        showMeetingRecording(record){
            this.eventBus.$emit('showMeetingRecording', (record.id));
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}
.code-form-row .el-form-item{
    margin-bottom: 10px !important;
}
</style>